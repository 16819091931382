import React, { useState, useEffect } from "react";
import { FaLock } from "react-icons/fa";
import styles from "./NewRol.module.css";
import {
  HeaderNavigation,
  TextInput,
  SelectInput,
  SlideNotification,
  CriticalErrorPage,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { redirectToMainPage } from "@Utilities/Navigation";
import { adaptGetModules_adapter } from "@Adapters/adaptGetModules.adapter";
import { capitalizeFirstLetter } from "@Utilities/capitalizeFirstLetter";
import { adaptPostNewRol } from "@Adapters/adaptPostNewRol.adapter";
import { LoadingPulseIcon } from "@Models/icons/icons";

interface Module {
  moduleId: number;
  moduleName: string;
  hierarchy: number;
  disabled?: boolean;
}

interface View {
  viewId: number;
  route: string;
  nameView: string;
  modules: Module[];
}
interface StartRoute {
  value: number;
  name: string;
}

export const NewRol: React.FC = () => {
  const [rolName, setRolName] = useState("");
  const [views, setViews] = useState<View[]>([]);
  const [startRoutes, setStartRoutes] = useState<StartRoute[]>([]);
  const [selectedView, setSelectedView] = useState<StartRoute | string>("");
  const [selectedModules, setSelectedModules] = useState<{
    [key: number]: number[];
  }>({});
  const [selectAll, setSelectAll] = useState(false);
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [isLoadingSaved, setIsLoadingSaved] = useState(false);
  const [loader, setLoader] = useState(true);
  const [criticalError, setCriticalError] = useState(false);

  useEffect(() => {
    (async () => {
      const response: any = await adaptGetModules_adapter();
      if (response.isSuccess) {
        setViews(response.data.views);
        setStartRoutes(response.data.startRoutes);
        setSelectedModules({});
        setLoader(false);
      } else {
        setCriticalError(true);
        setStateResponse({
          message: response.message,
          status: response.status,
        });
        setLoader(false);
      }
    })();
  }, []);

  const prevAction = () => {
    redirectToMainPage("nuevo-rol");
  };

  const handleSave = async () => {
    const viewModules = Object.entries(selectedModules).map(
      ([viewId, moduleIds]) => ({
        idViewModule: parseInt(viewId),
        idModule: moduleIds,
      })
    );

    const dataToSave = {
      nameRol: rolName,
      viewModules: viewModules,
      idStartView:
        typeof selectedView === "string"
          ? parseInt(selectedView)
          : selectedView.value,
    };
    await adaptPostNewRol(
      setStateResponse,
      setIsLoadingSaved,
      dataToSave,
      prevAction
    );
  };

  const isButtonDisabled =
    loader ||
    !rolName.trim() ||
    !selectedView ||
    Object.keys(selectedModules).length === 0;

  const headerProps = {
    title: "Nuevo rol",
    previousAction: prevAction,
    previousActionMovement: "back",
    buttonProps: {
      textBttn: "Guardar",
      handleClick: handleSave,
      isDisabled: isButtonDisabled || isLoadingSaved,
      isHidden: false,
      isPrimary: true,
      isLoading: isLoadingSaved,
    },
  };
  // Función para verificar si todos los módulos están seleccionados
  const areAllModulesSelected = () => {
    if (views.length === 0) return false; // Añade esta línea
    return views.every((view) =>
      view.modules
        .filter((m) => !m.disabled)
        .every((module) =>
          selectedModules[view.viewId]?.includes(module.moduleId)
        )
    );
  };

  // Efecto para actualizar el estado de selectAll
  useEffect(() => {
    setSelectAll(areAllModulesSelected());
  }, [selectedModules, views]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allModules = views.reduce((acc, view) => {
        acc[view.viewId] = view.modules
          .filter((m) => !m.disabled)
          .map((m) => m.moduleId);
        return acc;
      }, {} as { [key: number]: number[] });
      setSelectedModules(allModules);
    } else {
      setSelectedModules({});
    }
  };
  const handleMainViewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const view = views.find((v) => v.viewId === parseInt(e.target.value));
    if (view) {
      if (!selectedModules[view.viewId]) {
        setStateResponse({
          message: `El acceso a ${capitalizeFirstLetter(
            view.nameView
          )} se ha habilitado`,
          status: 200,
        });
      }
      setSelectedModules((prev) => {
        const newModules = { ...prev };
        newModules[view.viewId] = view.modules
          .filter((m) => !m.disabled)
          .map((m) => m.moduleId);
        return newModules;
      });
    }
    setSelectedView(e.target.value);
  };

  const handleViewToggle = (viewId: number) => {
    setSelectedModules((prev) => {
      const newModules = { ...prev };
      if (newModules[viewId]) {
        delete newModules[viewId];
      } else {
        const view = views.find((v) => v.viewId === viewId);
        newModules[viewId] = view
          ? view.modules.filter((m) => !m.disabled).map((m) => m.moduleId)
          : [];
      }
      return newModules;
    });

    // if the deselected view is the main view, the main view is deselected
    const view = views.find((v) => v.viewId === viewId);
    if (view && String(view.viewId) === selectedView) {
      setSelectedView("");
    }
  };

  const handleModuleToggle = (viewId: number, moduleId: number) => {
    setSelectedModules((prev) => {
      const viewModules = prev[viewId] || [];
      let newViewModules: number[];

      if (viewModules.includes(moduleId)) {
        newViewModules = viewModules.filter((id) => id !== moduleId);
      } else {
        newViewModules = [...viewModules, moduleId];
      }

      // Si no quedan módulos seleccionados, eliminamos la entrada para esta vista
      if (newViewModules.length === 0) {
        const { [viewId]: _, ...rest } = prev;
        return rest;
      }

      return {
        ...prev,
        [viewId]: newViewModules,
      };
    });
    // si no hay módulos seleccionados, se elimina la vista
    const view = views.find((v) => v.viewId === viewId);
    if (view && String(view.viewId) === selectedView) {
      if (selectedModules[view.viewId]?.length === 1) {
        setSelectedView("");
      }
    }
  };

  if (loader)
    return (
      <div id="viuti-front-mainContent" className={styles.container}>
        <figure className={styles.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );
  if (criticalError) {
    return (
      <div id="viuti-front-mainContent" className={styles.container}>
        <HeaderNavigation {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }
  return (
    <div id="viuti-front-mainContent" className={styles.container}>
      <HeaderNavigation {...headerProps} />
      <div className={styles.formContainer}>
        <TextInput
          label="Nombre"
          value={rolName}
          handleChange={(e) => setRolName(e.target.value)}
          placeholder={"Nombre del rol"}
          keyboardType={"text"}
          name={"name"}
          required
        />
        <SelectInput
          label="Vista principal del rol"
          placeholder={"Selecciona la vista principal"}
          value={selectedView}
          handleChange={handleMainViewChange}
          name={"mainView"}
          items={startRoutes}
          required
        />
        <div className={styles.accessContainer}>
          <h2>Accesos </h2>
          <p className={styles.subtitle}>
            Administra qué funciones puede realizar este rol.<span>*</span>
          </p>
          <label className={styles.selectAllCheckbox}>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <span>Seleccionar todo</span>
          </label>
          <ul className={styles.viewList}>
            {views.map((view) => (
              <li key={view.viewId} className={styles.viewItem}>
                <label className={styles.titleCheckbox}>
                  <input
                    type="checkbox"
                    checked={!!selectedModules[view.viewId]}
                    onChange={() => handleViewToggle(view.viewId)}
                  />
                  <h3 className={styles.viewTitle}>
                    Acceso a {capitalizeFirstLetter(view.nameView)}
                  </h3>
                </label>
                <ul className={styles.moduleList}>
                  {!!view.modules.length && (
                    <div className={styles.arrow}>
                      <span></span>
                      <span></span>
                    </div>
                  )}
                  <div className={styles.containerModule}>
                    {view.modules.map((module) => (
                      <li key={module.moduleId} className={styles.moduleItem}>
                        <label className={styles.checkboxLabel}>
                          {module.disabled && (
                            <IconWrapper
                              icon={FaLock}
                              className={styles.lockIcon}
                              title="No permitido"
                            />
                          )}
                          {!module.disabled && (
                            <input
                              type="checkbox"
                              checked={
                                module.disabled
                                  ? false
                                  : selectedModules[view.viewId]?.includes(
                                      module.moduleId
                                    )
                              }
                              onChange={() =>
                                handleModuleToggle(view.viewId, module.moduleId)
                              }
                              disabled={module.disabled}
                            />
                          )}
                          <p
                            className={
                              module.disabled ? styles.disabledModule : ""
                            }
                          >
                            {capitalizeFirstLetter(module.moduleName)}
                          </p>
                        </label>
                      </li>
                    ))}
                  </div>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};
