// @ts-ignore
import React, { useState } from "react";
import style from "./RoleList.module.css";
// @ts-ignore
import { Icon, IconWrapper } from "@viuti/recursos";
import {
  CommonArrowRightIcon,
  LoadingPulseIcon,
  TrashBinIcon,
} from "@Models/icons/icons";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { ModalDeleteRol } from "@Components/Modals/ModalDeleteRol";
import { useNavigate } from "react-router";
import { LiaKeySolid } from "react-icons/lia";

export const RoleList = ({
  items,
  emptyMessage,
  isLoading,
  setStateResponse,
  setRoles,
}) => {
  const [hoveredId, setHoveredId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [idRol, setIdRol] = useState(null);
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
    setIdRol(null);
  };

  if (isLoading)
    return (
      <figure className={style.loadingIcon}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </figure>
    );

  return items.length > 0 ? (
    <div className={style.tableContainer}>
      {items.map((item, index) => (
        <button
          key={item.idRol}
          className={style.table}
          style={{
            borderTop: index !== 0 ? "1px solid #dad1f4" : "",
          }}
          onMouseEnter={() => setHoveredId(item.idRol)}
          onMouseLeave={() => setHoveredId(null)}
          onClick={() => navigate(`info/${item.idRol}`)}
        >
          <h3 className={style.buttonRol}>{item.rol}</h3>
          <div className={style.rightSide}>
            {screenWidth >= 500 && (
              <button
                className={`${style.selectBubbleContainer}`}
                style={{
                  opacity: hoveredId === item.idRol ? 1 : 0,
                }}
                title="Ver Rol"
              >
                <Icon
                  path={CommonArrowRightIcon}
                  size={16}
                  alt={"Ver Rol"}
                  color={"#45348e"}
                />
              </button>
            )}
            {item.isSpecial && (
              <button
                title="Eliminar rol"
                className={style.trashCan}
                onClick={(e) => {
                  e.stopPropagation();
                  setIdRol(item.idRol);
                  setShowModal(true);
                }}
              >
                <Icon
                  path={TrashBinIcon}
                  size={16}
                  color={"#C36363"}
                  alt={"Eliminar"}
                />
              </button>
            )}
          </div>
        </button>
      ))}
      {showModal && (
        <ModalDeleteRol
          closeModal={closeModal}
          idRol={idRol}
          setRoles={setRoles}
          setToast={setStateResponse}
          nameRol={items?.find((item) => item?.idRol === idRol)?.rol}
          screenWidth={screenWidth}
        />
      )}
    </div>
  ) : (
    <div className={style.containerEmptyMessage}>
      <div className={style.containerIcon}>
        <IconWrapper icon={LiaKeySolid} />
      </div>
      <p className={style.emptyMessage}>
        {emptyMessage ||
          "No hay resultados para tu búsqueda. ¡Intenta usar otras palabras!"}
      </p>
    </div>
  );
};
