import React, { useState, useEffect } from "react";
import { FaLock } from "react-icons/fa";
import styles from "./RoleInformation.module.css";
import {
  HeaderNavigation,
  TextInput,
  SelectInput,
  SlideNotification,
  CriticalErrorPage,
  IconWrapper,
  // @ts-ignore
} from "@viuti/recursos";
import { redirectToMainPage } from "@Utilities/Navigation";
import { adaptGetModules_adapter } from "@Adapters/adaptGetModules.adapter";
import { capitalizeFirstLetter } from "@Utilities/capitalizeFirstLetter";
import { useParams } from "react-router";
import { adaptGetRolViewModule_adapter } from "@Adapters/adaptGetRolViewModule.adapter";
import { adaptPutRol_adapter } from "@Adapters/adaptPutRol.adapter";
import { Loader } from "@Components/Loader/Loader";
import { Arrow } from "@Components/Arrow/Arrow";

interface Module {
  moduleId: number;
  moduleName: string;
  hierarchy: number;
  disabled?: boolean;
}

interface View {
  viewId: number;
  route: string;
  nameView: string;
  modules: Module[];
}
interface StartRoute {
  value: string;
  name: string;
}

export const RoleInformation: React.FC = () => {
  const { id } = useParams();
  const [rolName, setRolName] = useState("");
  const [views, setViews] = useState<View[]>([]);
  const [startRoutes, setStartRoutes] = useState<StartRoute[]>([]);
  const [selectedView, setSelectedView] = useState<StartRoute | string>("");
  const [selectedModules, setSelectedModules] = useState<{
    [key: number]: number[];
  }>({});
  const [selectAll, setSelectAll] = useState(false);
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [rolNameOriginal, setRolNameOriginal] = useState("");
  const [isLoadingSaved, setIsLoadingSaved] = useState(false);
  const [loader, setLoader] = useState(true);
  const [criticalError, setCriticalError] = useState(false);
  const ADMINISTRATOR = "Administrador";
  const CASHIER = "Cajero";

  const isSpecial = () => {
    const normalizedRole = rolName.trim().toLowerCase();
    return (
      normalizedRole === ADMINISTRATOR.toLowerCase() ||
      normalizedRole === CASHIER.toLowerCase()
    );
  };

  const [initialData, setInitialData] = useState<{
    rolName: string;
    selectedView: string;
    selectedModules: { [key: number]: number[] };
  } | null>(null);

  useEffect(() => {
    if (id) {
      (async () => {
        const response: any = await adaptGetRolViewModule_adapter(id);
        const responseTwo: any = await adaptGetModules_adapter();
        if (response.isSuccess && responseTwo.isSuccess) {
          const initialRolName = response.data.roleName;
          const initialSelectedView = String(response.data.startView.viewId);
          const initialSelectedModules = response.data.viewModule.reduce(
            (acc: any, view: any) => {
              acc[view.viewId] = view.moduleId.map((m: any) => m.moduleId);
              return acc;
            },
            {}
          );
          setViews(responseTwo.data.views);
          setStartRoutes(responseTwo.data.startRoutes);
          setRolName(initialRolName);
          setRolNameOriginal(initialRolName);
          setSelectedView(initialSelectedView);
          setSelectedModules(initialSelectedModules);
          setLoader(false);
          setInitialData({
            rolName: initialRolName,
            selectedView: initialSelectedView,
            selectedModules: initialSelectedModules,
          });
        } else {
          setLoader(false);
          setCriticalError(true);
          if (!response.isSuccess) {
            setStateResponse({
              message: response.message,
              status: response.status,
            });
          }
          if (!responseTwo.isSuccess) {
            setStateResponse({
              message: responseTwo.message,
              status: responseTwo.status,
            });
          }
        }
      })();
    }
  }, [id]);

  const prevAction = () => {
    redirectToMainPage(`info/${id}`);
  };

  const handleSave = async () => {
    setIsLoadingSaved(true);

    if (!initialData) {
      setIsLoadingSaved(false);
      return;
    }

    const deleteView: number[] = [];
    const deleteModule: number[] = [];
    const newViewModule: { viewId: number; moduleId: number[] }[] = [];

    // Detectar vistas que han sido eliminadas
    Object.keys(initialData.selectedModules).forEach((viewId) => {
      if (!selectedModules[Number(viewId)]) {
        deleteView.push(Number(viewId));
      }
    });

    // Detectar módulos eliminados y módulos nuevos
    Object.entries(initialData.selectedModules).forEach(
      ([viewId, initialModules]) => {
        const currentModules = selectedModules[Number(viewId)] || [];

        // Módulos eliminados
        initialModules.forEach((moduleId) => {
          if (!currentModules.includes(moduleId)) {
            deleteModule.push(moduleId);
          }
        });

        // Módulos nuevos
        currentModules.forEach((moduleId) => {
          if (!initialModules.includes(moduleId)) {
            const existingViewModule = newViewModule.find(
              (vm) => vm.viewId === Number(viewId)
            );
            if (existingViewModule) {
              existingViewModule.moduleId.push(moduleId);
            } else {
              newViewModule.push({
                viewId: Number(viewId),
                moduleId: [moduleId],
              });
            }
          }
        });
      }
    );

    // Detectar vistas nuevas
    Object.keys(selectedModules).forEach((viewId) => {
      if (!initialData.selectedModules[Number(viewId)]) {
        newViewModule.push({
          viewId: Number(viewId),
          moduleId: selectedModules[Number(viewId)],
        });
      }
    });

    // Asegurarse de enviar los arreglos, aunque estén vacíos
    const data = {
      deleteView: deleteView,
      deleteModule: deleteModule,
      newViewModule: newViewModule,
      rolId: Number(id),
      newRolName: rolName !== initialData.rolName ? rolName : undefined,
      newStartView:
        selectedView !== initialData.selectedView
          ? typeof selectedView === "string"
            ? Number(selectedView)
            : Number(selectedView.value)
          : undefined,
    };

    // Llamada al adaptador
    await adaptPutRol_adapter(
      setStateResponse,
      setIsLoadingSaved,
      data,
      prevAction
    );
  };

  const hasChanges = () => {
    if (!initialData) return false;

    const hasRolNameChanged = initialData.rolName.trim() !== rolName.trim();
    const hasViewChanged = initialData.selectedView !== selectedView;
    const hasModulesChanged =
      JSON.stringify(initialData.selectedModules) !==
      JSON.stringify(selectedModules);

    return hasRolNameChanged || hasViewChanged || hasModulesChanged;
  };

  const isButtonDisabled =
    loader ||
    !rolName.trim() ||
    !selectedView ||
    !Object.keys(selectedModules).length ||
    !hasChanges();

  const headerProps = {
    title: rolNameOriginal || "",
    previousAction: prevAction,
    previousActionMovement: "back",
    buttonProps: {
      textBttn: "Guardar",
      handleClick: handleSave,
      isDisabled: isButtonDisabled || isLoadingSaved,
      isHidden: false,
      isPrimary: true,
      isLoading: isLoadingSaved,
    },
  };

  const handleMainViewChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const view = views.find((v) => v.viewId === parseInt(e.target.value));
    if (view) {
      if (!selectedModules[view.viewId]) {
        setStateResponse({
          message: `El acceso a ${capitalizeFirstLetter(
            view.nameView
          )} se ha habilitado`,
          status: 200,
        });
      }
      setSelectedModules((prev) => {
        const newModules = { ...prev };
        newModules[view.viewId] = view.modules
          .filter((m) => !m.disabled)
          .map((m) => m.moduleId);
        return newModules;
      });
    }
    setSelectedView(e.target.value);
  };
  // Función para manejar el cambio en el checkbox "Seleccionar todo"
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      // Si se selecciona "todo", activamos todas las vistas y módulos no deshabilitados
      const allModules = views.reduce((acc, view) => {
        acc[view.viewId] = view.modules
          .filter((m) => !m.disabled)
          .map((m) => m.moduleId);
        return acc;
      }, {} as { [key: number]: number[] });
      setSelectedModules(allModules);

      // Seleccionamos la primera vista como vista principal si no hay una seleccionada
      if (!selectedView && startRoutes.length > 0) {
        setSelectedView(startRoutes[0].value);
      }
    } else {
      // Si se deselecciona "todo", limpiamos todas las selecciones
      setSelectedModules({});
      setSelectedView("");
    }
  };

  // Efecto para actualizar el estado de "Seleccionar todo" cuando cambian las selecciones
  useEffect(() => {
    const allSelected = views.every(
      (view) =>
        selectedModules[view.viewId]?.length ===
        view.modules.filter((m) => !m.disabled).length
    );
    setSelectAll(
      allSelected &&
        views.length > 0 &&
        Object.keys(selectedModules).length === views.length
    );
  }, [selectedModules, views]);
  const handleViewToggle = (viewId: number) => {
    setSelectedModules((prev) => {
      const newModules = { ...prev };
      if (newModules[viewId]) {
        delete newModules[viewId];
      } else {
        const view = views.find((v) => v.viewId === viewId);
        newModules[viewId] = view
          ? view.modules.filter((m) => !m.disabled).map((m) => m.moduleId)
          : [];
      }
      return newModules;
    });
    // if the deselected view is the main view, the main view is deselected
    const view = views.find((v) => v.viewId === viewId);
    if (view && String(view.viewId) === selectedView) {
      setSelectedView("");
    }
  };

  const handleModuleToggle = (viewId: number, moduleId: number) => {
    setSelectedModules((prev) => {
      const viewModules = prev[viewId] || [];
      let newViewModules: number[];

      if (viewModules.includes(moduleId)) {
        newViewModules = viewModules.filter((id) => id !== moduleId);
      } else {
        newViewModules = [...viewModules, moduleId];
      }

      // Si no quedan módulos seleccionados, eliminamos la entrada para esta vista
      if (newViewModules.length === 0) {
        const { [viewId]: _, ...rest } = prev;
        return rest;
      }

      return {
        ...prev,
        [viewId]: newViewModules,
      };
    });
    // si no hay módulos seleccionados, se elimina la vista
    const view = views.find((v) => v.viewId === viewId);
    if (view && String(view.viewId) === selectedView) {
      if (selectedModules[view.viewId]?.length === 1) {
        setSelectedView("");
      }
    }
  };

  if (loader) return <Loader />;

  if (criticalError) {
    return (
      <div id="viuti-front-mainContent" className={styles.container}>
        <HeaderNavigation {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent" className={styles.container}>
      <HeaderNavigation {...headerProps} />
      <div className={styles.formContainer}>
        <TextInput
          label="Nombre"
          value={rolName}
          handleChange={(e) => setRolName(e.target.value)}
          placeholder={"Nombre del rol"}
          keyboardType={"text"}
          name={"name"}
          required
          disabled={isSpecial()}
        />
        <SelectInput
          label="Vista principal del rol"
          placeholder={"Selecciona la vista principal"}
          value={selectedView}
          handleChange={handleMainViewChange}
          name={"mainView"}
          items={startRoutes}
          required
          disabled={isSpecial()}
        />
        <div className={styles.accessContainer}>
          <h2>Accesos </h2>
          <p className={styles.subtitle}>
            Administra qué funciones puede realizar este rol.<span>*</span>
          </p>
          <label className={styles.selectAllCheckbox}>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              disabled={isSpecial()}
            />
            <span>Seleccionar todo</span>
          </label>
          <ul className={styles.viewList}>
            {views.map((view) => (
              <li key={view.viewId} className={styles.viewItem}>
                <label className={styles.titleCheckbox}>
                  <input
                    type="checkbox"
                    checked={!!selectedModules[view.viewId]}
                    onChange={() => handleViewToggle(view.viewId)}
                    disabled={isSpecial()}
                  />
                  <h3 className={styles.viewTitle}>
                    Acceso a {capitalizeFirstLetter(view.nameView)}
                  </h3>
                </label>
                <ul className={styles.moduleList}>
                  {!!view.modules.length && <Arrow />}
                  <div className={styles.containerModule}>
                    {view.modules.map((module) => (
                      <li key={module.moduleId} className={styles.moduleItem}>
                        <label className={styles.checkboxLabel}>
                          {module.disabled && (
                            <IconWrapper
                              icon={FaLock}
                              className={styles.lockIcon}
                              title="No permitido"
                            />
                          )}
                          {!module.disabled && (
                            <input
                              type="checkbox"
                              checked={
                                module.disabled
                                  ? false
                                  : selectedModules[view.viewId]?.includes(
                                      module.moduleId
                                    )
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleModuleToggle(view.viewId, module.moduleId)
                              }
                              disabled={module.disabled || isSpecial()}
                            />
                          )}
                          <p
                            className={
                              module.disabled ? styles.disabledModule : ""
                            }
                          >
                            {capitalizeFirstLetter(module.moduleName)}
                          </p>
                        </label>
                      </li>
                    ))}
                  </div>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};
